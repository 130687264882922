@use 'src/assets/styles/colors';
@use 'src/assets/styles/typography';

:export {
  green: colors.$validColor;
  red: colors.$denyColor;
}

.product {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 300px;
  background-color: colors.$white;
  padding: 20px;
  border-radius: 10px;
  gap: 20px;

  &Content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
    flex: 1;

    &Image {
      width: 180px;

      img {
        max-width: 180px;
        border-radius: 10px;
        background-color: colors.$background-grey;
      }
    }

    &Info {
      display: flex;
      flex-direction: column;
      width: 100%;

      &Header {
        &Title {
          @include typography.titoli;
          color: colors.$primaryColor;
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 10px;
        }

        &Category {
          @include typography.breadcrumb;
          color: colors.$greyMedium;
          display: flex;
          flex-direction: row;
          gap: 5px;

          span + span {
            &::before {
              content: '»';
              margin-right: 5px;
            }
          }
        }

        &Price {
          @include typography.breadcrumb;
          color: colors.$primaryColor;
          margin-top: 6px;
        }
      }

      &Body {
        display: flex;
        flex-direction: column;
        gap: 10px;

        &Description {
          @include typography.testi;
          color: colors.$secondaryColor;
        }

        &Specs {
          display: flex;
          flex-direction: row;
          row-gap: 10px;
          column-gap: 50px;
          flex-wrap: wrap;
        }
      }
    }

    &Brand {
      &:last-child {
        margin-left: auto;
      }

      & > img {
        width: 100px;
      }
    }
  }
}

.variation {
  display: flex;
  border-top: 1px dashed colors.$background-grey;
  gap: 20px;
  padding: 20px 0;

  &Image {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 180px;

    img {
      width: 100px;
      border-radius: 10px;
      background-color: colors.$background-grey;
    }
  }

  &Info {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;

    &Description {
      @include typography.testi;
      color: colors.$secondaryColor;

      &.empty {
        color: colors.$greyMedium;
      }
    }

    &Specs {
      display: flex;
      flex-direction: row;
      row-gap: 10px;
      column-gap: 50px;
      flex-wrap: wrap;
    }

    &Attributes {
      display: flex;
      gap: 20px;
    }
  }
}

.attributeInput {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;

  &Label {
    @include typography.voci;
  }

  &Value {
    @include typography.voci;
    background-color: colors.$background-grey;
    padding: 5px;
    border-radius: 5px;
    min-width: 100px;
    text-align: center;
  }
}

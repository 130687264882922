@use 'src/assets/styles/colors';
@use 'src/assets/styles/typography';

.content {
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  &Body {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    height: 100%;

    &Setting {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 20px;

      &Label {
        @include typography.testi;
      }
    }
  }
}

@use 'src/assets/styles/colors';

:export {
  green: colors.$validColor;
  red: colors.$denyColor;
  yellow: colors.$tertiaryColor;
  grey: colors.$background-grey;
}

.bill {
  display: grid;
  grid-template-columns: subgrid;
  grid-column: span 6;
  background-color: colors.$white;
  border-radius: 10px;
  padding: 20px;

  &Buttons {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    gap: 10px;
  }
}

@use 'src/assets/styles/colors';
@use 'src/assets/styles/typography';

:export {
  green: colors.$validColor;
  red: colors.$denyColor;
  yellow: colors.$tertiaryColor;
  grey: colors.$background-grey;
}

.task {
  display: flex;
  flex-direction: column;
  background-color: colors.$white;
  border-radius: 10px;
  padding: 20px;
  width: 100%;
  gap: 20px;

  &Body {
    width: 100%;
    display: flex;
    gap: 20px;
    width: 100%;

    &Content {
      display: flex;
      gap: 20px;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
    }

    &Id {
      @include typography.breadcrumb;
      color: colors.$primaryColor;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
    }
  }

  &Detail {
    border-top: 1px dashed colors.$background-grey;
    padding: 20px 0;
  }
}

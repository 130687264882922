@use 'src/assets/styles/colors';
@use 'src/assets/styles/typography';

:export {
  green: colors.$validColor;
  red: colors.$denyColor;
  yellow: colors.$tertiaryColor;
  grey: colors.$background-grey;
}

.content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;

  &Body {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    height: 100%;
  }
}

.variationAttributes {
  display: flex;
  flex-direction: row;
  gap: 10px;
  &Row {
    display: flex;
    flex-direction: row;
    gap: 20px;
    width: 100%;

    > b {
      @include typography.breadcrumb;
      color: colors.$primaryColor;
    }

    > span {
      @include typography.voci;
      color: colors.$secondaryColor;
    }

    &::after {
      content: '-';
    }
  }
}

.variationTitle {
  color: colors.$primaryColor;
  display: flex;
  flex-direction: column;
  gap: 3px;
  margin-top: 5px;

  .productTitle {
    @include typography.breadcrumb;
  }
}

.contentBody {
  display: flex;
  flex-direction: column;
}

.inventoryHeader {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 20px;
  padding: 20px;
}

.inventory {
  display: grid;
  gap: 20px;
  grid-template-rows: auto;
  grid-template-columns: 2fr 1fr 1fr 1fr;

  &Row {
    display: grid;
    grid-template-columns: subgrid;
    grid-column: span 5;
    background-color: colors.$white;
    border-radius: 10px;
    padding: 20px;

    &Buttons {
      display: flex;
      flex-direction: row;
      gap: 20px;
      justify-content: flex-end;

      &Group {
        display: flex;
        flex-direction: row;
      }
    }

    &Product {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: start;

      &NotFound {
        @include typography.voci;
        color: colors.$greyMedium;
      }

      &Image {
        width: 50px;
        height: 50px;
        border-radius: 10px;
        background-color: colors.$background-grey;
        margin-right: 20px;
      }

      &Content {
        display: flex;
        flex-direction: column;
        gap: 5px;

        &Title {
          @include typography.testi;
          color: colors.$primaryColor;
          font-weight: 600;
        }

        &Variation {
          @include typography.voci;
          color: colors.$greyMedium;
          display: flex;
          flex-direction: row;
          gap: 10px;
          flex-wrap: wrap;

          &Attribute {
            @include typography.voci;
            color: colors.$primaryColor;
            display: flex;
            flex-direction: row;
            gap: 3px;

            background-color: colors.$background-grey;
            padding: 5px;
            border-radius: 5px;
          }
        }
      }
    }
  }
}

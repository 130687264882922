@use 'src/assets/styles/colors';
@use 'src/assets/styles/typography';

.searchbar {
  display: flex;
  align-items: center;
  width: 100%;

  &Input {
    @include typography.testi;
    width: 100%;
    height: 36px;
    border: none;
    border-radius: 5px 0 0 5px;
    color: colors.$primaryColor;
    padding: 10px 20px;

    &::placeholder {
      color: colors.$grey;
    }

    &:focus {
      outline: none;
    }
  }

  &Icon {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: colors.$tertiaryColor;
    color: colors.$white;
    border-radius: 0 5px 5px 0;
    cursor: pointer;
    width: 36px;
    height: 36px;
  }
}

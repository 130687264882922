@use 'src/assets/styles/colors';
@use 'src/assets/styles/typography';

:export {
  green: colors.$validColor;
  red: colors.$denyColor;
  yellow: colors.$tertiaryColor;
  grey: colors.$background-grey;
}

.attribute {
  display: grid;
  grid-template-columns: subgrid;
  grid-column: span 3;

  padding: 20px;
  background-color: colors.$white;
  border-radius: 10px;

  &Buttons {
    display: flex;
    gap: 20px;
    justify-content: flex-end;
  }
}

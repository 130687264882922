@use 'src/assets/styles/colors';
@use 'src/assets/styles/typography';

.iconSpec {
  display: flex;
  flex-direction: row;
  gap: 4px;

  &Icon {
    display: flex;
    justify-content: center;
    color: colors.$greyMedium;
  }

  &Content {
    display: flex;
    flex-direction: column;
    gap: 2px;
    justify-content: center;

    &Label {
      @include typography.voci;
      color: colors.$greyMedium;
    }

    &Value {
      @include typography.voci;
      color: colors.$primaryColor;

      &Input {
        @include typography.testi;
        outline: none;
        border: none;
        border-bottom: 1px solid colors.$primaryColor;
        height: 36px;
        padding: 10px;
        width: 100px;
        display: block;
      }
    }
  }
}

@use 'src/assets/styles/colors';
@use 'src/assets/styles/typography';

:export {
  green: colors.$validColor;
  red: colors.$denyColor;
}

.swatch {
  position: relative;
  background-color: colors.$white;
  box-sizing: border-box;

  &Open {
    .swatchHeader {
      background-color: colors.$background-grey;
      border-radius: 5px 5px 0 0;
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
      border-right: 1px solid colors.$primaryColor;
      border-top: 1px solid colors.$primaryColor;
      border-left: 1px solid colors.$primaryColor;
    }

    .swatchContent {
      background-color: colors.$background-grey;
      border-radius: 0 0 5px 5px;
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
      border-right: 1px solid colors.$primaryColor;
      border-bottom: 1px solid colors.$primaryColor;
      border-left: 1px solid colors.$primaryColor;
    }
  }

  &Header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
    & > svg {
      color: colors.$tertiaryColor;
      margin-right: 10px;
    }
  }

  &Separator {
    height: 1px;
    width: 30%;
    background-color: colors.$primaryColor;
    margin: auto;
  }

  &Content {
    position: absolute;
    width: 100%;
    max-height: 150px;
    overflow: auto;
  }
}

.swatchItem {
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  width: 100%;
  cursor: pointer;

  .swatchOpen .swatchContent &:hover,
  &Selected {
    background-color: colors.$hoverSelection;
  }

  &Image {
    & > img {
      max-width: 35px;
    }
  }

  &Info {
    display: flex;
    flex-direction: column;
    justify-content: center;

    &Title {
      @include typography.breadcrumb;
      color: colors.$primaryColor;
    }

    &Attributes {
      display: flex;
      flex-direction: row;
      gap: 4px;
    }

    &Attribute + &Attribute {
      &::before {
        content: '-';
        margin-right: 4px;
      }
    }

    &Attribute {
      @include typography.vociMedium;
      color: colors.$secondaryColor;
    }
  }

  &Spec {
    margin-left: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    &Price {
      @include typography.breadcrumb;
      color: colors.$primaryColor;
    }

    &Stock {
      @include typography.voci;
    }
  }
}

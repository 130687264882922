@use 'src/assets/styles/colors';
@use 'src/assets/styles/typography';

.content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;

  &Body {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    height: 100%;
  }
}

@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@mixin menu {
  font-family: 'Roboto Condensed', sans-serif;
  font-optical-sizing: auto;
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-transform: uppercase;
}

@mixin testi {
  font-family: 'Roboto', sans-serif;
  font-optical-sizing: auto;
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

@mixin titoli {
  font-family: 'Roboto Condensed', sans-serif;
  font-optical-sizing: auto;
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
}

@mixin voci {
  font-family: 'Roboto Condensed', sans-serif;
  font-optical-sizing: auto;
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

@mixin vociMedium {
  font-family: 'Roboto Condensed', sans-serif;
  font-optical-sizing: auto;
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@mixin breadcrumb {
  font-family: 'Roboto Condensed', sans-serif;
  font-optical-sizing: auto;
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

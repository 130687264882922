@use 'src/assets/styles/colors';
@use 'src/assets/styles/typography';

:export {
  green: colors.$validColor;
  red: colors.$denyColor;
  yellow: colors.$tertiaryColor;
  grey: colors.$background-grey;
}

.toast {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;

  &Title {
    @include typography.testi;
    color: colors.$primaryColor;
  }

  &Content {
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    &Delete {
      @include typography.testi;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: colors.$denyColor;
      color: colors.$white;
      border-radius: 5px;
      padding: 10px;
      cursor: pointer;
      border: none;
    }

    &Abort {
      @include typography.testi;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: colors.$tertiaryColor;
      color: colors.$white;
      border-radius: 5px;
      padding: 10px;
      cursor: pointer;
      border: none;
    }
  }
}

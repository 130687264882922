@keyframes preloader-rotate {
  0% {
    transform: scale(1) rotate(0deg);
  }
  50% {
    transform: scale(0.8) rotate(360deg);
  }
  100% {
    transform: scale(1) rotate(720deg);
  }
}

@keyframes preloader-fadeOut {
  from {
    visibility: visible;
  }
  to {
    visibility: hidden;
  }
}

.loader {
  background-color: rgba(255, 255, 255, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 1;
  visibility: visible;
  z-index: 2500;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: preloader-fadeOut 20s ease both;
  transition: opacity 0.4s ease;
}

.loaderImg {
  width: 300px;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loaderImg::before {
  content: '';
  display: block;
  width: 50px;
  height: 50px;
  border: 2px solid #bbb;
  border-top-color: rgb(187, 187, 187);
  border-top-color: #000;
  border-radius: 50%;
  animation: preloader-rotate 2s cubic-bezier(0.63, 0.09, 0.26, 0.96) infinite;
}

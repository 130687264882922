.content {
    overflow: auto;
    height: 100%;

    >div:first-child {
        min-height: 100%;
    }

    &Body {
        padding: 20px;
        // display: flex;
        // flex-direction: column;
        // gap: 20px;

        display: grid;
        gap: 20px;
        grid-template-rows: auto;
        grid-template-columns: 2fr 1fr 1fr;
    }
}
@use 'src/assets/styles/colors';
@use 'src/assets/styles/typography';

.taskResult {
  display: flex;
  flex-direction: column;

  &Items {
    display: flex;
    flex-direction: row;

    &Item {
      display: flex;
      flex-direction: row;
    }
  }
}

@use 'src/assets/styles/colors';
@use 'src/assets/styles/typography';

.toolbar {
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 10px;

  & > .toolbarItem:last-child {
    margin-top: auto;
  }
}

.toolbarItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &Label {
    @include typography.voci;
  }
}

@use 'src/assets/styles/colors';
@use 'src/assets/styles/typography';

.contentBodyScanner {
  display: flex;
  flex-direction: column;
  background-color: colors.$white;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  justify-content: center;
  align-items: center;

  &Image {
    color: colors.$tertiaryColor;
  }

  &Text {
    @include typography.titoli;
    color: colors.$primaryColor;
  }

  &UnderText {
    @include typography.menu;
    cursor: pointer;
    color: colors.$primaryColor;
    margin-top: 35px;
  }

  &Input {
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: center;
    align-items: center;
    margin-top: 20px;

    &Field {
      @include typography.testi;
      outline: none;
      border: none;
      border-bottom: 1px solid colors.$primaryColor;
      height: 36px;
      padding: 10px;
    }

    &Button {
      @include typography.menu;
      cursor: pointer;
      color: colors.$white;
      background-color: colors.$tertiaryColor;
      border-radius: 5px;
      padding: 10px;
      margin-left: 20px;
      border: none;
    }
  }
}

@use 'src/assets/styles/colors';
@use 'src/assets/styles/typography';

.contentHeader {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;

  .contentTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;

    &Text {
      @include typography.titoli;
      color: colors.$primaryColor;
    }

    &Actions {
      display: flex;
      gap: 10px;
      flex-wrap: wrap;
      justify-content: end;
    }
  }
}

.contentHeaderGroup {
  display: flex;
  flex-direction: row;
  background-color: colors.$background-grey;
  align-items: center;
  gap: 10px;

  &Buttons {
    display: flex;
    background-color: #ececec;
    border-radius: 5px;
    border: 1px solid #e4e4e4;
    gap: 5px;
  }

  &Label {
    @include typography.voci;
    color: colors.$greyMedium;
  }
}

.contentHeaderButton {
  @include typography.voci;

  display: flex;
  justify-content: center;
  align-items: center;

  &Text {
    padding: 4px 6px;
    color: colors.$primaryColor;
    min-width: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
    gap: 5px;
    user-select: none;
  }

  &Text:hover {
    background-color: #f3f3f3;
  }

  &TextSelected {
    background-color: #f3f3f3;
  }
}

@use 'src/assets/styles/colors';

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  color: colors.$white;
  cursor: pointer;
  width: 60px;
  height: 80px;
}

.iconSelected {
  display: flex;
  flex-direction: column;
  width: 100%;

  &Before {
    position: relative;
    height: 10px;
    width: 100%;
    background-color: colors.$primaryColor;
  }

  &Before::before {
    position: absolute;
    content: '';
    top: 0px;
    right: 0px;
    width: 100%;
    height: 10px;
    background-color: colors.$secondaryColor;
    border-radius: 0 0 10px 0;
  }

  &After {
    position: relative;
    height: 10px;
    width: 100%;
    background-color: colors.$primaryColor;
  }

  &After::before {
    position: absolute;
    content: '';
    bottom: 0px;
    right: 0px;
    width: 100%;
    height: 10px;
    background-color: colors.$secondaryColor;
    border-radius: 0 10px 0 0;
  }

  &Content {
    display: flex;
    position: relative;
    background-color: colors.$primaryColor;
    padding: 14px;
    width: 60px;
    height: 60px;
    align-items: center;
    justify-content: center;
    border-radius: 20px 0 0 20px;

    > * {
      color: colors.$tertiaryColor;
      font-size: 24px;
      cursor: pointer;
    }
  }
}

.appShell {
  display: flex;
  flex-direction: row;
  height: 100%;

  .sideBar {
    display: flex;
    flex-direction: row;
    height: 100%;

    &Parent {
      display: flex;
      flex-direction: column;
      min-width: 60px;
      height: 100%;
      background-color: colors.$secondaryColor;
      align-items: center;
      justify-content: center;
      gap: 0px;
      color: colors.$white;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    background-color: colors.$background-grey;
    gap: 20px;
  }
}

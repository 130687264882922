@use 'src/assets/styles/colors';
@use 'src/assets/styles/typography';

.sidebar {
  display: flex;
  flex-direction: column;
  min-width: 200px;
  height: 100%;
  background-color: colors.$primaryColor;
  padding: 20px;
  gap: 80px;

  &Header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    &Logo {
      width: 120px;
    }

    &Icon {
      cursor: pointer;
      color: colors.$white;
    }
  }

  &Menu {
    display: flex;
    flex-direction: column;
    gap: 20px;
    color: colors.$white;

    &ItemSelected {
      background-color: colors.$secondaryColor;
      .sidebarMenuItemText,
      .sidebarMenuItemIcon {
        color: colors.$tertiaryColor;
      }
    }

    &Item {
      display: flex;
      align-items: center;
      gap: 14px;
      padding: 6px 9px;
      border-radius: 5px;
      cursor: pointer;
      text-decoration: none;

      &Icon {
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        color: colors.$white;
      }

      &Text {
        @include typography.menu;
        color: colors.$white;
      }

      &:hover {
        background-color: colors.$secondaryColor;
      }
    }
  }
}

.sidebarClosed {
  min-width: 0px;
  width: 78px;

  .sidebarHeader {
    justify-content: center;
    &Logo {
      display: none;
    }

    &Icon {
      cursor: pointer;
      color: colors.$white;
    }
  }

  .sidebarMenu {
    position: relative;
    &Item {
      &Text {
        display: none;
      }

      &:hover {
        background-color: colors.$secondaryColor;

        .sidebarMenuItemText {
          display: block;
          z-index: 1;
          width: fit-content;
          white-space: nowrap;
          position: absolute;
          left: 33px;
          background-color: colors.$secondaryColor;
          padding: 8px 6px;
          border-radius: 5px;
        }
      }
    }

    &ItemSelected {
      background-color: colors.$secondaryColor;
      color: colors.$tertiaryColor;
    }
  }
}

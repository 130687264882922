.content {
  overflow: auto;
  height: 100%;

  &Body {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}

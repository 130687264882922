@use 'src/assets/styles/colors';
@use 'src/assets/styles/typography';

.tags {
  display: flex;
  flex-direction: row;
  gap: 5px;
  flex-wrap: wrap;

  &Empty {
    color: colors.$greyMedium;
    display: flex;
    align-items: center;
  }

  & > svg {
    color: colors.$tertiaryColor;
  }
}

.tag {
  @include typography.voci;
  padding: 3px 4px;
  border-radius: 5px;
  background-color: colors.$tertiaryColor;
  color: colors.$white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 3px;

  svg {
    cursor: pointer;
  }
}

$primaryColor: #092143;
$secondaryColor: #1d3554;
$tertiaryColor: #f5a623;

$validColor: #1fb02d;
$denyColor: #d33300;

$hoverSelection: #f1c884;

$background-grey: #f3f3f3;
$grey: #333;
$greyMedium: #999;
$white: #fff;

// Typography
$textLight: #fff;
$textDark: #333;

.primaryColorText {
  color: $primaryColor;
}

.secondaryColorText {
  color: $secondaryColor;
}

.tertiaryColorText {
  color: $tertiaryColor;
}

.validColorText {
  color: $validColor;
}

.denyColorText {
  color: $denyColor;
}
